<template>
  <div>
    <v-row>
      <v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
        <User-Side-Bar />
      </v-col>
      <v-col class="col-md-9 col-lg-10 grey lighten-3 px-md-4">
        <div :class="{ 'pr-8': $vuetify.breakpoint.smAndDown }">
          <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
            <router-link
              class="black--text text-decoration-none"
              :to="`/dashboard`"
            >
              <v-icon class="mt-n1 mr-2 hidden-md-and-up"
                >mdi-arrow-left</v-icon
              >
            </router-link>
            Property List
          </h3>
          <div class="float-right mt-n8 mr-n3 mr-md-6">
            <Notification />
          </div>
        </div>
        <div v-show="skeLoader">
          <skeleton-loader />
        </div>
        <v-tabs class="mb-8" v-model="tab" align-with-title>
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab>
            All <span class="grey--text">({{ allProducts.length }})</span>
          </v-tab>
          <v-tab>
            Active <span class="grey--text">({{ activeProducts.length }})</span>
          </v-tab>
          <v-tab>
            Not Publish
            <span class="grey--text">({{ notPublishProducts.length }})</span>
          </v-tab>
          <v-tab>
            Declined
            <span class="grey--text">({{ declinedProducts.length }})</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-data-table
              v-show="skeLoader == false"
              :headers="headers"
              :items="allProducts"
              class="elevation-1"
              :search="allSearch"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    v-model="allSearch"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                  <v-spacer></v-spacer>

                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="red lighten-2"
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogPublish" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Publish item</v-card-title>
                      <div class="px-4">
                        <v-select
                          v-model="publish"
                          :items="publishItems"
                          item-text="name"
                          item-value="value"
                          label="select one"
                          outlined
                        ></v-select>
                      </div>
                      <br />
                      <div class="text-center px-4" v-show="publish == 2">
                        <p>Reasons for Rejection</p>
                        <v-textarea
                          outlined
                          v-model="message"
                          class="mt-2"
                          label="type message here"
                        ></v-textarea>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closePublish"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="green lighten-2"
                          @click="publishItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogFeatured" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Featured item</v-card-title>
                      <div class="px-4">
                        <v-select
                          v-model="featured"
                          :items="featuredItems"
                          item-text="name"
                          item-value="value"
                          label="select one"
                          outlined
                        ></v-select>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeFeatured"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="green lighten-2"
                          @click="featuredItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <!-- <template v-slot:item.publish="{ item }">
                 <v-chip
                  :color="getPublishColor(item.publish)"
                  small
                  dark
                >
                  {{
                  item.publish == 0
                    ? "Not Publish"
                    : item.publish == 1
                    ? "Active"
                    : "Declined"
                  }}
                </v-chip> 
              </template> -->
              <!-- <template v-slot:item.converted_featured="{ item }">
                <v-chip
                  :color="getColor(item.converted_featured)"
                  small
                  dark
                >
                  {{item.converted_featured }}
                </v-chip>
                
              </template> -->
              <!-- <template v-slot:item.actions="{ item }">
                <v-icon
                  v-show="
                    authUserType == 'super_admin' ||
                    authUserType == 'editor' ||
                    authUserType == 'admin' || 
                    authUserType == 'admin_can_add'
                  "
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-show="
                    authUserType == 'super_admin' ||
                      authUserType == 'editor' ||
                      authUserType == 'admin' ||
                      authUserType == 'admin_can_add'
                  "
                  small
                  class="mr-2"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-show="
                    authUserType == 'super_admin' ||
                      authUserType == 'editor' ||
                      authUserType == 'admin' ||
                      authUserType == 'admin_can_add'
                  "
                  class="mr-2"
                  small
                  @click="publishItem(item)"
                >
                  mdi-eye
                </v-icon>
                <v-icon
                  v-show="
                    authUserType == 'super_admin' || authUserType == 'editor'
                  "
                  small
                  @click="featuredItem(item)"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </template> -->
            </v-data-table>
          </v-tab-item>

          <v-tab-item>
            <v-data-table
              v-show="skeLoader == false"
              :headers="headers"
              :items="activeProducts"
              sort-by="title"
              class="elevation-1"
              :search="activeSearch"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    v-model="activeSearch"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                  <v-spacer></v-spacer>

                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="red lighten-2"
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogPublish" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Publish item</v-card-title>
                      <div class="px-4">
                        <v-select
                          v-model="publish"
                          :items="publishItems"
                          item-text="name"
                          item-value="value"
                          label="select one"
                          outlined
                        ></v-select>
                      </div>
                      <br />
                      <div class="text-center px-4" v-show="publish == 2">
                        <p>Reasons for Rejection</p>
                        <v-textarea
                          outlined
                          v-model="message"
                          class="mt-2"
                          label="type message here"
                        ></v-textarea>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closePublish"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="green lighten-2"
                          @click="publishItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogFeatured" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Featured item</v-card-title>
                      <div class="px-4">
                        <v-select
                          v-model="featured"
                          :items="featuredItems"
                          item-text="name"
                          item-value="value"
                          label="select one"
                          outlined
                        ></v-select>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeFeatured"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="green lighten-2"
                          @click="featuredItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <!-- <template v-slot:item.publish="{ item }">
                <v-chip
                  :color="getPublishColor(item.publish)"
                  small
                  dark
                >
                  {{
                  item.publish == 0
                    ? "Not Publish"
                    : item.publish == 1
                    ? "Active"
                    : "Declined"
                  }}
                </v-chip> 
              </template>
              <template v-slot:item.converted_featured="{ item }">
                <v-chip
                  :color="getColor(item.converted_featured)"
                  small
                  dark
                >
                  {{item.converted_featured }}
                </v-chip>
                
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
                <v-icon class="mr-2" small @click="publishItem(item)">
                  mdi-eye
                </v-icon>
                <v-icon
                  v-show="
                    authUserType == 'super_admin' || authUserType == 'editor'
                  "
                  small
                  @click="featuredItem(item)"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </template> -->
            </v-data-table>
          </v-tab-item>

          <v-tab-item>
            <v-data-table
              v-show="skeLoader == false"
              :headers="headers"
              :items="notPublishProducts"
              sort-by="title"
              class="elevation-1"
              :search="notPubSearch"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    v-model="notPubSearch"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                  <v-spacer></v-spacer>

                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="red lighten-2"
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogPublish" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Publish item</v-card-title>
                      <div class="px-4">
                        <v-select
                          v-model="publish"
                          :items="publishItems"
                          item-text="name"
                          item-value="value"
                          label="select one"
                          outlined
                        ></v-select>
                      </div>
                      <br />
                      <div class="text-center px-4" v-show="publish == 2">
                        <p>Reasons for Rejection</p>
                        <v-textarea
                          outlined
                          v-model="message"
                          class="mt-2"
                          label="type message here"
                        ></v-textarea>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closePublish"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="green lighten-2"
                          @click="publishItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogFeatured" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Featured item</v-card-title>
                      <div class="px-4">
                        <v-select
                          v-model="featured"
                          :items="featuredItems"
                          item-text="name"
                          item-value="value"
                          label="select one"
                          outlined
                        ></v-select>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeFeatured"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="green lighten-2"
                          @click="featuredItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <!-- <template v-slot:item.publish="{ item }">
                <v-chip
                  :color="getPublishColor(item.publish)"
                  small
                  dark
                >
                  {{
                  item.publish == 0
                    ? "Not Publish"
                    : item.publish == 1
                    ? "Active"
                    : "Declined"
                  }}
                </v-chip> 
              </template>
              <template v-slot:item.converted_featured="{ item }">
                <v-chip
                  :color="getColor(item.converted_featured)"
                  small
                  dark
                >
                  {{item.converted_featured }}
                </v-chip>
                
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
                <v-icon class="mr-2" small @click="publishItem(item)">
                  mdi-eye
                </v-icon>
                <v-icon
                  v-show="
                    authUserType == 'super_admin' || authUserType == 'editor'
                  "
                  small
                  @click="featuredItem(item)"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </template> -->
            </v-data-table>
          </v-tab-item>

          <v-tab-item>
            <v-data-table
              v-show="skeLoader == false"
              :headers="headers"
              :items="declinedProducts"
              sort-by="title"
              class="elevation-1"
              :search="declinedSearch"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    v-model="declinedSearch"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                  <v-spacer></v-spacer>

                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="red lighten-2"
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogPublish" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Publish item</v-card-title>
                      <div class="px-4">
                        <v-select
                          v-model="publish"
                          :items="publishItems"
                          item-text="name"
                          item-value="value"
                          label="select one"
                          outlined
                        ></v-select>
                      </div>
                      <br />
                      <div class="text-center px-4" v-show="publish == 2">
                        <p>Reasons for Rejection</p>
                        <v-textarea
                          outlined
                          v-model="message"
                          class="mt-2"
                          label="type message here"
                        ></v-textarea>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closePublish"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="green lighten-2"
                          @click="publishItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogFeatured" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Featured item</v-card-title>
                      <div class="px-4">
                        <v-select
                          v-model="featured"
                          :items="featuredItems"
                          item-text="name"
                          item-value="value"
                          label="select one"
                          outlined
                        ></v-select>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeFeatured"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="green lighten-2"
                          @click="featuredItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <!-- <template v-slot:item.publish="{ item }">
                <v-chip
                  :color="getPublishColor(item.publish)"
                  small
                  dark
                >
                  {{
                  item.publish == 0
                    ? "Not Publish"
                    : item.publish == 1
                    ? "Active"
                    : "Declined"
                  }}
                </v-chip> 
              </template> -->
              <!-- <template v-slot:item.converted_featured="{ item }">
                <v-chip
                  :color="getColor(item.converted_featured)"
                  small
                  dark
                >
                  {{item.converted_featured }}
                </v-chip>
                
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
                <v-icon class="mr-2" small @click="publishItem(item)">
                  mdi-eye
                </v-icon>
                <v-icon
                  v-show="authUserType == 'super_admin' || authUserType == 'editor'"
                  small
                  @click="featuredItem(item)"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </template> -->
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SkeletonLoader from "../layout/SkeletonLoader";
import { mapActions, mapState } from "vuex";
import UserSideBar from "../../components/auth/UserSideBar";
import Notification from "../../components/auth/NotificationWrapper";
export default {
  components: { UserSideBar, Notification, SkeletonLoader },
  metaInfo() {
    return {
      title: `Property List - Housefinder.com.ng`,
    };
  },
  data: () => ({
    authUserType: "",
    tab: null,
    allProducts: [],
    message: "",
    skeLoader: false,
    loading: false,
    publish: "",
    publishItems: [
      { name: "publish", value: 1 },
      { name: "Reject", value: 2 },
    ],
    featured: "",
    featuredItems: [
      { name: "featured", value: 1 },
      { name: "Not featured", value: 0 },
    ],
    activeSearch: "",
    allSearch: "",
    notPubSearch: "",
    declinedSearch: "",
    dialog: false,
    dialogDelete: false,
    dialogPublish: false,
    dialogFeatured: false,
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Agent", value: "user.full_name" },
      { text: "Developer", value: "developer" },
      { text: "Developer No", value: "developer_number" },
      { text: "Featured", value: "converted_featured" },
      { text: "Published", value: "publish" },
      { text: "Price", value: "converted_price" },
      { text: "Date", value: "post_date" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: "",
    editedItem: {},
  }),

  computed: {
    ...mapState(["products"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    activeProducts() {
      return this.allProducts.filter(p => p.publish == 1);
    },
    notPublishProducts() {
      return this.allProducts.filter(p => p.publish == 0);
    },
    declinedProducts() {
      return this.allProducts.filter(p => p.publish == 2);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPublish(val) {
      val || this.closePublish();
    },
    dialogFeatured(val) {
      val || this.closeFeatured();
    },
  },
  methods: {
    ...mapActions([
      "deleteProduct",
      "publishProduct",
      "featuredProduct",
      "getProducts",
      "getLocation",
    ]),
    editItem(item) {
      this.$router.push(`/product/edit/${item.slug}`);
    },

    deleteItem(item) {
      this.editedIndex = item.id;

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    publishItem(item) {
      this.editedIndex = item.id;
      this.publish = item.publish;
      this.message = "";
      this.editedItem = item;
      this.dialogPublish = true;
    },

    featuredItem(item) {
      this.editedIndex = item.id;
      this.featured = item.featured;
      this.editedItem = item;
      this.dialogFeatured = true;
    },

    async deleteItemConfirm() {
      this.loading = true;
      let data = await this.deleteProduct(this.editedIndex);
      if (data.data) {
        this.$toastr.s(data.message);
        this.loading = false;
      } else {
        this.$toastr.e(data.message);
        this.loading = false;
      }
      this.closeDelete();
    },

    async publishItemConfirm() {
      this.loading = true;
      let field = {
        id: this.editedIndex,
        publish: this.publish,
        message: this.message,
      };
      let data = await this.publishProduct(field);
      this.loading = false;
      if (data.data) {
        this.closePublish();
        this.$toastr.s(data.message);
      } else {
        this.$toastr.e(data.message);
        this.closePublish();
      }
    },

    async featuredItemConfirm() {
      this.loading = true;
      let field = { id: this.editedIndex, featured: this.featured };
      let data = await this.featuredProduct(field);
      this.loading = false;
      if (data.data) {
        this.$toastr.s(data.message);
        //this.getProducts();
      } else {
        this.$toastr.e(data.message);
      }
      this.closeFeatured();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closePublish() {
      this.dialogPublish = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = "";
      });
    },
    closeFeatured() {
      this.dialogFeatured = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    getColor (feature) {
      if (feature == 'True') return 'blue'
      else return ''
    },
    getPublishColor (publish) {
      if ( publish ==0) return 'dark'
      else if (publish ==1) return 'green'
      else return 'red'
    },
    async getData() {
      this.authUserType = localStorage.getItem("userType");
      this.skeLoader = true;
      await this.getProducts();
      this.allProducts = this.products;
      this.skeLoader = false;
    },
  },
  created() {
    this.getData();
  },
};
</script>
