var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-center d-none d-md-block"},[(_vm.userImage)?_c('v-avatar',[_c('img',{attrs:{"src":_vm.userImage,"alt":"profile_image"}})]):_c('v-avatar',[_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":"profile_image"}})]),_c('div',{staticClass:"mt-1 text-subtitle-2"},[_vm._v(_vm._s(_vm.full_name))])],1),_c('v-divider'),_c('v-list',{},[_c('v-list-item-group',[_c('v-list-item',{staticClass:"mt-n2 text-subtitle-1",class:{ 'activeBlue darken-2 white--text': _vm.currentUrl == '/dashboard' },on:{"click":function($event){return _vm.switchUrl('/dashboard')}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(`mdi-view-dashboard`)}})],1),_c('v-list-item-content',{staticClass:"ml-n4 text-subtitle-2"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(`Dashboard`)}})],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userType == 'admin' ||
          _vm.userType == 'editor' ||
          _vm.userType == 'super_admin' ||
          _vm.userType == 'admin_can_add'
        ),expression:"\n          userType == 'admin' ||\n          userType == 'editor' ||\n          userType == 'super_admin' ||\n          userType == 'admin_can_add'\n        "}],staticClass:"mt-n2 text-subtitle-1",class:{
          'activeBlue darken-2 white--text': _vm.currentUrl == '/admin/product/index',
        },on:{"click":function($event){return _vm.switchUrl('/admin/product/index')}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(`mdi-home-group`)}})],1),_c('v-list-item-content',{staticClass:"ml-n4 text-subtitle-2"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(`All Properties`)}})],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userType == 'admin' ||
            _vm.userType == 'editor' ||
            _vm.userType == 'super_admin' ||
            _vm.userType == 'admin_can_add'

        ),expression:"\n          userType == 'admin' ||\n            userType == 'editor' ||\n            userType == 'super_admin' ||\n            userType == 'admin_can_add'\n\n        "}],staticClass:"mt-n2 text-subtitle-1",class:{ 'activeBlue darken-2 white--text': _vm.currentUrl == '/admin/users' },on:{"click":function($event){return _vm.switchUrl('/admin/users')}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(`mdi-account-group`)}})],1),_c('v-list-item-content',{staticClass:"ml-n4 text-subtitle-2"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(`All Users`)}})],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userType == 'super_admin' ||
          _vm.userType == 'editor'
        ),expression:"userType == 'super_admin' ||\n          userType == 'editor'\n        "}],staticClass:"mt-n2 text-subtitle-1",class:{
          'activeBlue darken-2 white--text': _vm.currentUrl == '/category/index',
        },on:{"click":function($event){return _vm.switchUrl('/category/index')}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(`mdi-tag`)}})],1),_c('v-list-item-content',{staticClass:"ml-n4 text-subtitle-2"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(`Categories`)}})],1)],1),_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,staticClass:"mt-n2 text-subtitle-1",class:{ 'activeBlue darken-2 white--text': _vm.currentUrl == item.url },on:{"click":function($event){return _vm.switchUrl(item.url)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',{staticClass:"ml-n4 text-subtitle-2"},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1),_c('v-badge',{directives:[{name:"show",rawName:"v-show",value:(item.text == 'Inbox' && _vm.msgCount > 0),expression:"item.text == 'Inbox' && msgCount > 0"}],staticClass:"float-right mr-2 mt-2",attrs:{"color":"green","content":_vm.msgCount}})],1)}),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userType == 'super_admin' || _vm.userType == 'editor'),expression:"userType == 'super_admin' || userType == 'editor'"}],staticClass:"mt-n2 text-subtitle-1",class:{
          'activeBlue darken-2 white--text': _vm.currentUrl == '/auth/verify-user',
        },on:{"click":function($event){return _vm.switchUrl('/auth/verify-user')}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(`mdi-check-decagram`)}})],1),_c('v-list-item-content',{staticClass:"ml-n4 text-subtitle-2"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(`Verify User`)}})],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }