<template>
	<div>
		<v-row>
			<v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
                 <User-Side-Bar/> 
            </v-col>
            <v-col class="col-md-9 col-lg-10 grey lighten-3 px-md-4">
                <div :class="{'pr-8': $vuetify.breakpoint.smAndDown}">
                    <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
                        <router-link class="black--text text-decoration-none" :to="`/dashboard`">
                            <v-icon class='mt-n1 mr-2 hidden-md-and-up'>mdi-arrow-left</v-icon>
                        </router-link>  
                        User List
                    </h3>
                    <div class="float-right mt-n8 mr-n3 mr-md-6">
						<Notification/>		
					</div>    
                </div>
				<div v-if="skeLoader">
					<skeleton-loader/>
				</div>
				<v-chip
					v-if="skeLoader == false"
					dark
					class="mb-4"
					>
					{{`${users.length} Total Users`}}
				</v-chip>
				<v-data-table
					v-if="skeLoader == false"
					:headers="headers"
					:items="users"
					sort-by="title"
					class="elevation-1"
					:search="search"
				>   
					<template v-slot:top>
						<v-toolbar
						flat
						>
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details
							>
							</v-text-field>
							<v-spacer></v-spacer>
							<v-dialog v-model="dialogDelete" max-width="500px">
								<v-card>
									<v-card-title class="text-h5">Are you sure you want to delete this User?</v-card-title>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn class="white--text" color="grey darken-3 darken-1" @click="closeDelete">Cancel</v-btn>
										<v-btn v-show="loading == false" class="white--text" color="red lighten-1" @click="deleteItemConfirm">OK</v-btn>
										<v-progress-circular
											v-show="loading"
											:width="3"
											class="ml-4"
											color="green"
											indeterminate
										>
										</v-progress-circular>
										<v-spacer></v-spacer>
									</v-card-actions>
								</v-card>
							</v-dialog>
							<v-dialog v-model="dialogPublish" max-width="500px">
								<v-card>
									<v-card-title class="text-h5">Change User Type</v-card-title>
									<v-card-actions>
										<v-spacer></v-spacer>
										<div>
											<v-select
												v-model="userType"
												:items="userTypeItems"
												item-text="name" 
												item-value="value"
												label="select one"
												outlined
											></v-select>
										</div>
										<v-btn class="white--text" color="grey darken-3" @click="closePublish">Cancel</v-btn>
										<v-btn v-show="loading == false" class="white--text" color="green lighten-1" @click="publishItemConfirm">OK</v-btn>
										<v-progress-circular
											v-show="loading"
											:width="3"
											class="ml-4"
											color="green"
											indeterminate
										>
										</v-progress-circular>
										<v-spacer></v-spacer>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</v-toolbar>
					</template>
					<!-- <template v-slot:item.actions="{ item }"> -->
						<template >
						<v-icon
							v-show="authUserType == 'super_admin' || 
								authUserType == 'editor'|| 
								authUserType == 'admin' "
							small
							class="mr-2"
							@click="editItem(item)"
						>
							mdi-pencil
						</v-icon>
						<v-icon
							v-show="authUserType == 'super_admin'"
							small
							class="mr-2"
							@click="deleteItem(item)"
						>
							mdi-delete
						</v-icon>
						<v-icon
							v-show="authUserType == 'super_admin'"
							class="mr-2"
							small
							@click="publishItem(item)"
						>
							mdi-eye
						</v-icon>
					</template>
					<template v-slot:no-data>
						<v-btn
							color="primary"
							@click="initialize"
						>
							Reset
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import SkeletonLoader from '../layout/SkeletonLoader';
	import Notification from '../../components/auth/NotificationWrapper';
	import { mapActions, mapState } from "vuex";
	import UserSideBar from '../../components/auth/UserSideBar';
	export default {
		components: {UserSideBar, Notification, SkeletonLoader},
		metaInfo() {
			return {
				title: `User List - Housefinder`,
				
			}
		},
		data: () => ({
			skeLoader: false,
			loading: false,
			userType: '',
			userTypeItems: [
				{name: 'user', value: 'user'},
				{name: 'agent', value: 'agent'},
                {name: 'admin', value: 'admin'},
				{name: 'editor', value: 'editor'},
                {name: 'super_admin', value: 'super_admin'},
				{name: 'admin_can_add', value: 'admin_can_add'},
			],
			authUserType: '',
			search: '',
			dialog: false,
			dialogDelete: false,
			dialogPublish: false,
			headers: [
				{
				text: 'Full Name',
				align: 'start',
				sortable: false,
				value: 'full_name',
				},
				{ text: 'Email', value: 'email' },
				{ text: 'User Type', value: 'user_type' },
				{ text: 'Phone Number', value: 'phone_number' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			editedIndex: '',
			editedItem: {},
			defaultItem: {},
		}),

		computed: {
            ...mapState({users: state => state.auth.users}),
			formTitle () {
				return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
			},
		},

		watch: {
			dialog (val) {
				val || this.close()
			},
			dialogDelete (val) {
				val || this.closeDelete()
			},
			dialogPublish (val) {
				val || this.closePublish()
			},
		},
		methods: {
			...mapActions(["deleteUser", "updateUserType", "getUsers"]),
			editItem (item) {
			
				this.$router.push(`/admin/users/${item.id}`);
			},

			deleteItem (item) {
				this.editedIndex = item.id;

				this.editedItem = Object.assign({}, item)
				this.dialogDelete = true
			},
			publishItem (item) {
				this.editedIndex = item.id;
                this.userType = item.user_type;
				this.editedItem = item;
				this.dialogPublish = true
			},
			async deleteItemConfirm () {
				this.loading = true;
				let data = await this.deleteUser(this.editedIndex)
				if(data.success == true){
					this.loading = false;
					this.$toastr.s(data.message);
					
				}
				else{
					this.loading = false;
					this.$toastr.e(data.message);
				}
				this.closeDelete()
			},

			async publishItemConfirm () {
				this.loading = true;
				let field = {id: this.editedIndex, user_type: this.userType};
				let response = await this.updateUserType(field);
				if(response.data.success == true){
					this.loading = false;
					this.$toastr.s('Update successful');
                    this.getUsers();
				}
				else{
					this.$toastr.e('an error occured');
					this.loading = false;
				}
				this.closePublish()
			},
			close () {
				this.dialog = false
				this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
				})
			},

			closeDelete () {
				this.dialogDelete = false
				this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
				})
			},
			closePublish () {
				this.dialogPublish = false
				this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
				})
			},
			save () {
				if (this.editedIndex > -1) {
					Object.assign(this.desserts[this.editedIndex], this.editedItem)
				} else {
					this.desserts.push(this.editedItem)
				}
				this.close()
			},
			textColor(item){
				return item.publish ? '' : 'red--text'
			},
			async getData(){
				this.skeLoader = true;
				this.authUserType = localStorage.getItem('userType');
				await this.getUsers();
				console.log(this.users)
				this.skeLoader = false;
			},
		},
		created () {
			this.getData();
		},
	}
</script>